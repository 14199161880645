// Import libraries
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Img from 'gatsby-image';


// Import global styles
import GlobalStyle from '../styles/global';


// Import components
import Layout from '../layout/Layout';
import SEO from '../layout/SEO';
import LocationMap from '../components/Map';


// Component styles
const StyledBanner = styled.div`
    position: relative;
    background-image: url(${props => !!props.image.childImageSharp ? props.image.childImageSharp.fluid.src : props.image});
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    height: calc(100vh - 15rem);
    overflow: hidden;

    & .content {
        position: relative;
        z-index: 1;
        display: grid;
        grid-template-rows: 1fr 6rem;
        align-items: center;
        justify-items: center;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .6);

        & .container {
            display: block;
            padding: 0 var(--padding-right) 0 var(--padding-left);
            width: 100%;
            max-width: var(--max-width);
        }

        & .inner h1 {
            font-size: calc(40px + (80 - 40) * ((100vw - 320px) / (2200 - 320)));
            line-height: 1.2;
            color: #fff;
            
            & .primary {
                color: var(--color-primary);
            }

            @media only screen and (min-width: 1600px) {
                font-size: 55px;
            }

            @media only screen and (min-width: 1900px) {
                font-size: 70px;
            }
        }

        & .scroll-down-button {
            display: grid;
            align-items: center;
            justify-items: center;
            width: 6rem;
            height: 6rem;
            font-size: 2.5rem;
            background-color: #fff;
            color: #000;
            cursor: pointer;
            transition: background-color .25s ease-in-out,
                        color .25s ease-in-out;
            
            &:hover {
                background-color: var(--color-primary);
                color: #fff;
            }
        }
    }
`;

const StyledContactMethods = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    background-color: var(--color-background-2);

    & .content {
        display: grid;
        grid-template-rows: repeat(2, max-content);
        align-content: center;
        grid-row-gap: 2rem;
        padding: 10rem var(--padding-right) 10rem var(--padding-left);
        text-align: center;
        max-width: var(--max-width);
        margin: 0 auto;
        
        & h1 {
            font-size: var(--font-size-body-h1);
            font-weight: var(--font-weight-body-h1);
            line-height: var(--line-height-body-h1);
        }

        & .feature__summary {
            font-size: var(--font-size-body-p);
            font-weight: var(--font-weight-body-p);
            line-height: var(--line-height-body-p);
        }
    }

    & .contact-methods {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-content: center;
        grid-column-gap: 5rem;
        margin-top: 5rem;

        @media only screen and (max-width: 350px) {
            grid-template-columns: 1fr;
            grid-row-gap: 5rem;
        }
        
        & .contact-method {

            &__link {
                display: grid;
                grid-template-columns: 1fr;
                grid-row-gap: 10px;
                justify-items: center;

                &-text {
                    font-size: 3rem;
                    font-weight: 400;
                    color: var(--color-primary);
                }

                & span {
                    font-size: 2rem;
                }
            }
            
            &__link:link,
            &__link:visited,
            &__link:hover,
            &__link:focus,
            &__link:active {
                color: #000;
                text-decoration: none;
                outline: 0;
                transition: color .25s ease;
                font-size: 3rem;
                font-weight: 100;
                cursor: pointer;
            }

            &__icon {
                display: grid;
                align-items: center;
                justify-items: center;
                width: 5rem;
                height: 5rem;
                border: 3px solid #000;
                border-radius: 50%;
                font-size: 2.5rem;
                transition: border-color .25s ease;

                &--sms {
                    font-size: 3rem;
                }
            }
        }
    }
`;

const StyledVisitUs = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    background-color: #fff;

    & .content {
        display: grid;
        grid-template-rows: repeat(2, max-content);
        align-content: center;
        grid-row-gap: 2rem;
        padding: 10rem var(--padding-right) 10rem var(--padding-left);
        text-align: center;
        max-width: var(--max-width);
        margin: 0 auto;
        
        & h1 {
            font-size: var(--font-size-body-h1);
            font-weight: var(--font-weight-body-h1);
            line-height: var(--line-height-body-h1);
        }

        & .feature__summary {
            font-size: var(--font-size-body-p);
            font-weight: var(--font-weight-body-p);
            line-height: var(--line-height-body-p);
        }

        & .location {
            display: grid;
            grid-template-columns: min-content max-content;
            align-items: center;
            justify-content: center;

            @media only screen and (max-width: 400px) {
                grid-template-columns: 1fr;
                grid-row-gap: 1rem;
                justify-items: center;
            }

            &__details {
                display: grid;
                grid-row-gap: 1rem;
            }

            &__name {
                font-size: 3rem;
                font-weight: 500;
            }

            &__address {
                display: grid;
                grid-template-columns: 1fr;
                grid-row-gap: 5px;
                font-size: 2rem;
                color: #676565;
                font-family: inherit;
                line-height: 1.5;
            }
        }
    }
`;

const StyledContactUs = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    background-color: var(--color-background-2);
    
    & .content {
        display: grid;
        grid-template-rows: repeat(2, max-content);
        align-content: center;
        grid-row-gap: 2rem;
        padding: 10rem var(--padding-right) 10rem var(--padding-left);
        text-align: center;
        max-width: var(--max-width);
        margin: 0 auto;
        
        & h1 {
            font-size: var(--font-size-body-h1);
            font-weight: var(--font-weight-body-h1);
            line-height: var(--line-height-body-h1);
        }

        & .feature__summary {
            font-size: var(--font-size-body-p);
            font-weight: var(--font-weight-body-p);
            line-height: var(--line-height-body-p);
        }
    }
`;

const StyledForm = styled.form`
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    grid-row-gap: 1rem;

    & input::placeholder,
    & textarea::placeholder {
        color: #777676;
    }

    & input,
    & textarea {
        font-family: inherit;
        font-size: inherit;
        border-radius: 0;
        border: 1px solid #ccc;
        padding: 1rem 2rem;
        outline: none;
        width: 100%;
    }

    & textarea {
        resize: none;
    }

    & .button {
        display: flex;
        flex-direction: row;
        background-color: var(--color-primary);
        border: 2px solid var(--color-primary);
        border-radius: 0;
        font-size: 2rem;
        padding: 1rem 2rem;
        color: #fff;
        font-weight: 400;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        & span {
            margin-right: 10px;
        }
    }

    & .button:hover,
    & .button:focus {
        outline: 0;
        transform: translateY(-3px);
        box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
    }

    & .button:active {
        outline: 0;
        transform: translateY(0);
        box-shadow: none;
    }
`;



// Create component
const ContactPage = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    companyName
                    phoneNumber {
                        link
                        display
                    }
                    whatsappNumber {
                        link
                        display
                    }
                    email
                    googleMapsAPIKey
                }
            }

            contactDetailsJson {
                email
                phone_number_display
                phone_number_link
                whatsapp_number_display
                whatsapp_number_link
                address
            }

            bannerImage: file(relativePath: { eq: "banner/particulier/Administratie-voor-particuliere.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2048, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            locationIcon: file(relativePath: { eq: "Location-pointer.png" }) {
                childImageSharp {
                    fixed(width: 100, height: 100) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }
        }
    `);


    // Handle scrolling down to next section on page
    const handleScrollDown = () => {
        // Get header height difference between static header height and scollling header height and get banner height
        const headerHeightDifference = (parseFloat(window.getComputedStyle(document.querySelector('html')).getPropertyValue('font-size')) * 15) - (parseFloat(window.getComputedStyle(document.querySelector('html')).getPropertyValue('font-size')) * 10);
        const bannerHeight = document.querySelector('.banner').offsetHeight;
        

        // Scroll to place underneath banner
        window.scrollTo({
            top: (bannerHeight + headerHeightDifference),
            left: 0,
            behavior: 'smooth'
        });
    };

    return (
        <React.Fragment>
            <GlobalStyle />
            <SEO
                title="Contact"
                description="Neem gerust contact op voor een vrijblijvend gesprek of kom gezellig langs op ons administratiekantoor. Wij kunnen uiteraard ook bij u langskomen."
            />
            <Layout>
                <StyledBanner image={data.bannerImage} className="banner">
                    <div className="content">
                        <div className="inner container">
                            <h1>Kom <span className="primary">gezellig</span> langs de <span className="primary">koffie</span> staat altijd <span className="primary">klaar</span>.</h1>
                        </div>

                        <div className="container">
                            <div className="scroll-down-button" role="button" tabIndex={0} onClick={handleScrollDown} onKeyDown={handleScrollDown}>
                                <FontAwesomeIcon icon={faArrowDown} className="icon" />
                            </div>
                        </div>
                    </div>
                </StyledBanner>

                <StyledContactMethods>
                    <div className="content">
                        <h1>Wij staan voor u klaar</h1>
                        
                        <p className="feature__summary">Neem gerust contact met ons op, u kunt ons bereiken op de verschillende manieren die hieronder staan aangegeven.</p>

                        <div className="contact-methods">
                            <div className="contact-method">
                                <a href={`tel:${data.contactDetailsJson.phone_number_link}`} className="contact-method__link" aria-label="Bel ons">
                                    <div className="contact-method__icon">
                                        <FontAwesomeIcon icon={faPhoneAlt} />
                                    </div>
                                    <p className="contact-method__link-text">Bel</p>
                                    <span>{data.contactDetailsJson.phone_number_display}</span>
                                </a>
                            </div>

                            <div className="contact-method">
                                <a href={`whatsapp:${data.contactDetailsJson.whatsapp_number_link}`} className="contact-method__link" aria-label="Whatsapp ons">
                                    <div className="contact-method__icon">
                                        <FontAwesomeIcon icon={faWhatsapp} />
                                    </div>
                                    <p className="contact-method__link-text">Whatsapp</p>
                                    <span>{data.contactDetailsJson.whatsapp_number_display}</span>
                                </a>
                            </div>

                            <div className="contact-method">
                                <a href={`mailto:${data.contactDetailsJson.email}`} target="_blank" rel="noopener noreferrer" className="contact-method__link" aria-label="Email ons">
                                    <div className="contact-method__icon">
                                        <FontAwesomeIcon icon={faEnvelope} />
                                    </div>
                                    <p className="contact-method__link-text">Email</p>
                                    <span>{data.contactDetailsJson.email}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </StyledContactMethods>

                <StyledVisitUs>
                    <div className="content">
                        <h1>De koffie staat voor u klaar!</h1>

                        <p className="feature__summary">U kunt ook altijd langskomen bij ons op kantoor voor een vrijblijvend gesprek.</p>

                        <div className="location">
                            <Img fixed={data.locationIcon.childImageSharp.fixed} alt="" className="location__icon" />
                            <div className="location__details">
                                <p className="location__name">{data.site.siteMetadata.companyName}</p>
                                <pre className="location__address" dangerouslySetInnerHTML={{ __html: data.contactDetailsJson.address }} />
                            </div>
                        </div>
                    </div>
                </StyledVisitUs>

                <StyledContactUs>
                    <div className="content">
                        <h1>Of vul ons contactformulier in</h1>

                        <p className="feature__summary">U kunt ook altijd een bericht achterlaten door het onderstaande contactformulier in te vullen, dan nemen wij zo spoedig mogelijk contact met u op.</p>

                        <StyledForm name="contact-page-contact-form" action="/verstuurd?type=contact-page-contact-form" method="POST" enctype="application/x-www-form-urlencoded" data-netlify="true">
                            <input type="hidden" name="form-name" value="contact-page-contact-form" />
                            <input type="text" name="name" placeholder="Uw naam" required />
                            <input type="text" name="phone-number" placeholder="Uw telefoonnummer" required />
                            <input type="text" name="email" placeholder="Uw email" required />
                            <textarea name="message" placeholder="Uw bericht" rows="6" required></textarea>
                            <button type="submit" className="button">
                                <span>Verzenden</span>
                                <FontAwesomeIcon icon={faLongArrowRight} className="icon" />
                            </button>
                        </StyledForm>
                    </div>
                </StyledContactUs>

                <LocationMap
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${data.site.siteMetadata.googleMapsAPIKey}`}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `400px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                />
            </Layout>
        </React.Fragment>
    );
};


// Export component by default
export default ContactPage;