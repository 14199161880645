// Import libraries
import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps';


// Component styles
const StyledInfoWindow = styled.div`
    display: grid;
    grid-row-gap: 1rem;

    & h1 {
        font-size: 4rem;
        font-weight: 100;
        color: #36404B;
    }

    & h2 {
        font-size: 2.2rem;
        font-weight: 100;
        color: var(--color-primary);
        margin-top: -10px;
    }

    & p {
        line-height: 1.5;
        color: #36404B;
    }

    & .link {
        color: var(--color-primary);
        text-decoration: none;
    }
`;


// Create component
const LocationMap = withScriptjs(withGoogleMap((props) => {
    const [infoWindowIsOpen, setInfoWindowIsOpen] = useState(true);

    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    companyName
                    googleMapsNavigationLink
                    googleMapsCoordinates {
                        latitude
                        longitude
                    }
                }
            }
        }
    `);

    // Create custom marker and scale it to 50x50px
    var icon = {
        url: '/images/Location-pointer.svg', // url
        scaledSize: new window.google.maps.Size(50, 50), // scaled size
        origin: new window.google.maps.Point(0,0), // origin
        anchor: new window.google.maps.Point(0, 0) // anchor
    };

    return (
        <GoogleMap
            defaultCenter={{ lat: data.site.siteMetadata.googleMapsCoordinates.latitude, lng: data.site.siteMetadata.googleMapsCoordinates.longitude }}
            defaultZoom={15}
        >
            <Marker
                position={{ lat: data.site.siteMetadata.googleMapsCoordinates.latitude, lng: data.site.siteMetadata.googleMapsCoordinates.longitude }}
                onClick={() => setInfoWindowIsOpen(true)}
                icon={icon}
            >
                {infoWindowIsOpen && (
                    <InfoWindow onCloseClick={() => setInfoWindowIsOpen(false)}>
                        <StyledInfoWindow>
                            <h1>Ons Kantoor</h1>
                            <h2>{data.site.siteMetadata.companyName}</h2>
                            <p>Hier kunt u ons vinden. De koffie staat altijd voor u klaar.</p>
                            <a href={data.site.siteMetadata.googleMapsNavigationLink} className="link" target="_blank" rel="noopener noreferrer">Klik hier voor de routebeschrijving naar ons kantoor.</a>
                        </StyledInfoWindow>
                    </InfoWindow>
                )}
            </Marker>
        </GoogleMap>
    );
}));


// Export component by default
export default LocationMap;